import React from 'react'
import {Deutsch, English, Type, LinkABOUT, Page, Back_To_All_Projects} from '../../components/linksetc.js'
import Layout from '../../components/layout'

import img1 from "../../images/bluelion2/1.png"
import img2 from "../../images/bluelion2/2.png"
import img3 from "../../images/bluelion2/3.png"
import img4 from "../../images/bluelion2/4.png"

import img5 from "../../images/bluelion2/5.png"
import img6 from "../../images/bluelion2/6.png"
import img7 from "../../images/bluelion2/7.png"

import img8 from "../../images/bluelion2/8.png"


// for the scrolldown
var Scroll  = require('react-scroll');
var ScrollLink       = Scroll.Link;
var Element    = Scroll.Element;






export default ({ location }) => (
  <Layout>


      <Page location={location}>




      <div className="project_description">

        <English Language={location.search}>
              <Type>
                    Bluelion is a Zurich-based startup incubator that also offers innovation programs to large companies.
                    <p/>
                    I created a verbal identity in line with the visual branding created by <a href="http://www.distylerie.com/" target="_blank">distylerie</a> . I also wrote the text for the website, making sure to address both target groups, large corporations and small startups.
                    <p/>
                    <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>

              </Type>
        </English>

        <Deutsch Language={location.search}>
              <Type>
                    Bluelion ist ein Startup-Incubator in Zürich, das auch Innovationsprogramme für grosse Firmen anbietet.
                    <p/>
                    Ich erschuf eine verbale Identität passend zum visuellen Branding, erstellt von <a href="http://www.distylerie.com/" target="_blank">distylerie</a> . Ich schrieb auch den Text für die Webseite und stellte sicher, dass sich beide Zielgruppen (Firmen und Startups) angesprochen fühlen.
                  <p/>
                  <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>
              </Type>
        </Deutsch>


    </div>

    <Element name="test1"></Element>





    <img src={img1}/>
    <img src={img2}/>
    <img src={img3}/>
    <img src={img4}/>
    <img src={img5}/>
    <img src={img6}/>
    <img src={img7}/>
    <img src={img8}/>








      <Back_To_All_Projects location={location} />



      </Page>
    </Layout>

)
